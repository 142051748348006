<script lang="ts" setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	CHANGE_LANGUAGE_TITLE,
	CHANGE_LANGUAGE_SUBTITLE,
	CHANGE_LANGUAGE_MESSAGE,
	SAVE,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { getLanguage } from '@/utils/helpers';
import {
	PrimeText,
	PrimeButton,
	PrimeRadio,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { changeLanguage } from '@/api';

const componentName = useComponentName();
const { localizationCookie, setLocalizationCookie } = useAppCookies();
const userStore = useUserStore();
const { userLocales, countryCode } = storeToRefs(userStore);

const selectedLanguage = ref('');
const loading = ref(false);

const language = getLanguage(localizationCookie);

const handleSubmit = async () => {
	try {
		loading.value = true;
		setLocalizationCookie(`${selectedLanguage.value}-${countryCode.value}`);
		await changeLanguage(selectedLanguage.value);
		document.location.reload();
	} catch (error) {
		sendErrorInfo(error, componentName);
	} finally {
		loading.value = false;
	}
};

watch(
	userLocales,
	(value) => {
		if (value.find((item) => item.code === language)) {
			selectedLanguage.value = language;
		} else {
			selectedLanguage.value = value.find((locale) => locale.is_default)!.code;
		}
	},
	{ immediate: true }
);
</script>

<template>
	<ModalCommonContent>
		<template #header>
			{{ $t(CHANGE_LANGUAGE_TITLE) }}
		</template>
		<template #default>
			<div class="change-language__content">
				<PrimeText tag="p" weight="600">
					{{ $t(CHANGE_LANGUAGE_SUBTITLE) }}
				</PrimeText>
				<PrimeText tag="p" color="grey-600" size="xs" weight="500">
					{{ $t(CHANGE_LANGUAGE_MESSAGE) }}
				</PrimeText>
			</div>
			<div class="languages-wrapper">
				<div v-for="{ code, name } in userLocales" :key="code">
					<PrimeRadio
						v-model="selectedLanguage"
						:input-id="code"
						name="language"
						:val="code"
						:label="name"
					/>
				</div>
			</div>
		</template>
		<template #actions>
			<PrimeButton
				type="gradient"
				full-width
				:label="$t(SAVE)"
				:disabled="selectedLanguage === language"
				@click="handleSubmit"
			/>
		</template>
	</ModalCommonContent>
	<PrimeLoader v-if="loading" />
</template>

<style lang="scss" scoped>
.change-language__content {
	display: flex;
	flex-direction: column;
	text-align: start;
}

.languages-wrapper {
	margin-top: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	justify-content: flex-start;
	align-items: flex-start;
}
</style>
